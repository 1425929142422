import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { httpDelete, httpGet, httpPost } from '../../fetch';
import { getRedirect } from '../helpers/redirect';
import moment from 'moment';

const Document = (props) => {
    const [document, setDocument] = useState({date:'', signatory:[], notes:[], note:''});
    const [notes, setNotes] = useState([]);
    
    const fetchNotes = async () =>{
        const { data } = await httpGet(`/notes`);
        if(data.data){
            setNotes(data.data);
            console.log(data.data);
        }
    }
    
    useEffect( async () => {
        const href = window.location.href.split('/');
        const id = href[href.length -1]
        const { data } = await httpGet('/documents/'+id);
        setDocument({...data.data, date: moment(data.data.date).utc().format('YYYY-MM-DD') })
        fetchNotes();
    },[])
    
    
    const save = async() =>{
        await httpPost(`/documents/${document._id}`, document, getUser());
        window.location.href = getRedirect(document);
    }

    const getUser = () =>{
        const user = localStorage.getItem('user');
        return user;
    }

    const delNote = async () =>{
        if(document.note !== '' ) {
            if(document.note !== 'OTRO...'){
                await httpDelete(`/notes`,document.note, 'System')
                fetchNotes();
            }
        }
    }

    const addNote = async () =>{
        if(document.note !== '' && document.customNote !== '' ) {
            if(document.note === 'OTRO...'){
                const customNote = document.customNote;
                await httpPost(`/notes`, { note: customNote }, 'System')
                fetchNotes();
            }

            setDocument(
                { ...document, 
                    notes: [...document.notes, 
                        { note: document.note === 'OTRO...' ? document.customNote : document.note, 
                            user: getUser(), 
                            date: moment().format('YYYY-MM-DD') 
                        }
                    ] 
                }
            )
        }
    }
    
    return (
        <Container>
            <br/>
            <Row style={{borderBottom:'solid 1px #dee2e6', marginBottom: '1rem', paddingBottom: '1rem'}}>
                <Col xs={5}><h4>Confirmar Firmas</h4></Col>
                <Col xs={3}><Button onClick={e => { window.location.href = getRedirect(document) }}>Cancelar</Button></Col>
                <Col xs={3}><Button color='primary' onClick={e=> save()}>Guardar</Button></Col>
            </Row>
            <Row style={{borderBottom:'solid 1px #dee2e6', marginBottom: '1rem', paddingBottom: '1rem'}}>
                <Col xs={5}><h5>Última revisión: {document.updatedAt ? moment(document.updatedAt).utcOffset("-5").format('DD/MM/YYYY') : 'NO HAY REGISTRO'}</h5></Col>
            </Row>
            <Form>
                <Row>
                    <Col sm={6} md={4}>
                        <FormGroup>
                            <Label htmlFor='series'>Serie</Label>
                            <Input type='select' value={document.series} onChange={e => setDocument({ ...document, series: e.target.value })} disabled>
                            <option value='Mediador 168'>Mediador 168</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col sm={6} md={4}>
                        <FormGroup>
                            <Label htmlFor='date'>Fecha</Label>
                            <Input name='date' id='date' type='date' value={document.date} onChange={e=> setDocument({...document, date: e.target.value})} disabled></Input>
                        </FormGroup>
                    </Col>
                    <Col sm={6} md={4}>
                        <FormGroup>
                            <Label htmlFor='serial' >Folio</Label>
                            <Input type='text' id='serial' name='serial' value={document.serial} disabled />
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
            <br />
            <h6>Firmantes</h6>
            <Table>
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Tipo firma</th>
                        <th style={{textAlign:'center'}}>Firmado</th>
                        <th>Fecha</th>
                        <th>Usuario</th>
                    </tr>
                </thead>
                <tbody>
                    {document.signatory.map(s => {
                        return (
                            <tr key={document.signatory.indexOf(s)}>
                                <td>{s.name} {s.middleName} {s.lastName}</td>
                                <td>{s.isElectronic ? 'Electrónica' : 'Autógrafa'}</td>
                                <td style={{textAlign:'center'}}>
                                    <Input type='checkbox' value={s.isSigned} checked={s.isSigned} onChange={e=> 
                                        setDocument({...document, signatory: document.signatory.map(x => x === s ? ({...x, isSigned: !s.isSigned, signedDate: s.isSigned ? '' : moment().format('DD/MM/YYYY'), user: s.isSigned ? '' : getUser()}): x,) })} disabled={s.isDisabled}></Input>
                                </td>
                                <td>
                                    {s.signedDate}
                                </td>
                                <td>
                                    {s.user}
                                </td>
                            </tr>)
                    })}
                </tbody>
            </Table>
            <br />
            <Row>
                <Col xs={document.note !== 'OTRO...' ? 10 : 5}>
                    <FormGroup>
                        <Label htmlFor='note'>Agregar nota</Label>
                        <Input type='select' value={document.note} onChange={e => {setDocument({...document, note: e.target.value})}}>
                            <option value=''>Seleccionar nota...</option>
                            {
                                notes && notes.length > 0 && notes.map( n => (
                                    <option value={n.note}>{n.note}</option>
                                ))
                            }
                            {/* <option value='FIRMA CANCELADA'>FIRMA CANCELADA</option>
                            <option value='FIRMA POSPUESTA'>FIRMA POSPUESTA</option>
                            <option value='SOLICITAR HOJA DE FIRMAS FIRMAMEX'>SOLICITAR HOJA DE FIRMAS FIRMAMEX</option>
                            <option value='FACTURA PENDIENTE'>FACTURA PENDIENTE</option>
                            <option value='COORDINAR FIRMA PENDIENTE CON EJECUTIVO'>COORDINAR FIRMA PENDIENTE CON EJECUTIVO</option>
                            <option value='SOLICITAR ANEXOS ORIGINALES'>SOLICITAR ANEXOS ORIGINALES</option>
                            <option value='IDENTIFICACIONES PENDIENTES'>IDENTIFICACIONES PENDIENTES</option>
                            <option value='OTRO...'>OTRO...</option> */}
                        </Input>                        
                    </FormGroup>
                </Col>
                {document.note === 'OTRO...' ?
                    <Col xs={5}>
                        <FormGroup>
                            <Label>Nueva Nota</Label>
                            <Input value={document.customNote} onChange={e => setDocument({ ...document, customNote: e.target.value.toUpperCase() })} />
                        </FormGroup>
                    </Col>
                : ''}
                <Col xs={1}>
                    <Button onClick={e => addNote()} style={{ marginTop: '2rem' }}>+</Button>
                </Col>
                {
                    document.note && document.note !=='' && document.note !== 'OTRO...' ?
                    (
                        <Col xs={1}>
                            <Button onClick={e => delNote()} style={{ marginTop: '2rem' }}>-</Button>
                        </Col>
                    ) : ''
                }
            </Row>
            <Row>
                {document.notes && document.notes.length > 0 ?
                    <Col sm={12}>
                        <h6>Notas</h6>
                        <Table>
                            <thead>
                                <th>Fijar</th>
                                <th>Fecha</th>
                                <th>Usuario</th>
                                <th>Nota</th>
                            </thead>
                            <tbody>
                                {document.notes ? document.notes.map(n => {
                                    return (
                                        <tr key={document.notes.indexOf(n)}>
                                            <td><input type='radio' name='mota' checked={n.isFixed} 
                                                    onChange={e=> setDocument({...document, notes: document.notes.map(nt => nt === n ? {...n, isFixed: true} : {...nt, isFixed: false})})}
                                                /></td>
                                            <td>{moment(n.date).utc().format('DD/MM/YYYY')}</td>
                                            <td>{n.user}</td>
                                            <td>{n.note}</td>
                                        </tr>
                                    )
                                }) : ''}
                            </tbody>
                        </Table>
                    </Col>
                    : ''}
            </Row>
        </Container>
    );
};

export default Document;