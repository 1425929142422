import React, { useState } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';


const Navigation = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(!dropdownOpen);

    function isAdmin() {
        return localStorage.userType === 'Admin'
    }

    function logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('email');
        localStorage.removeItem('userType');
        window.location.href = '/'
    }

    return (
        <div>
            <a href='#' onClick={e =>logout()} style={{position:'absolute', top: 0, right: 10, color:'white'}}>Salir</a>
            <Nav tabs>
                <NavItem>
                    <NavLink href="#" active={props.link === 'Mediador 168'} onClick={e => props.changeLink('Mediador 168')}><span className='d-none d-md-inline-block'>Mediador</span> 168</NavLink>
                </NavItem>
                {isAdmin() ?
                <NavItem>
                    <NavLink href="#" active={props.link === 'usuarios'} onClick={e => props.changeLink('usuarios')}>Usuarios</NavLink>
                </NavItem>
                :''}
            </Nav>
        </div>
    );
}

export default Navigation;