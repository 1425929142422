import React, { useState, useEffect } from 'react';
import { Container } from 'reactstrap';
import Navigation from './components/Navigation';
import Documents from './pages/Documents';
import Users from './pages/Users';

const Main = (props) => {

    const [link, setLink] = useState('');

    useEffect(()=>{
        const href = window.location.href.split('#');
        if(href.length > 1){
            const tab = href[href.length - 1]
            if (tab === '168')
                changeLink('Mediador 168')
        }
    },[]);

    function changeLink(newLink) {
        setLink(newLink);
    }

    return (
        <Container>
            
            <Navigation link={link} changeLink={changeLink} />
            {link === 'usuarios' ?
                <Users />
                :
                <Documents series={link} />
            }
        </Container>
    );
};

export default Main;