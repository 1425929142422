import axios from 'axios';

// const backend = 'https://folios.api.psrasesores.com/api';
const backend = 'https://med168.api.handkey.mx/api';
// const backend = 'http://localhost:6001/api';


export async function httpGet(path) {
    const response = await axios.get(backend + path);
    return response;
}

export function httpGetOne(path, id) {
    return axios.get(backend + path + '/' + id);
}

export function httpPost(path, item, user) {
    return axios.post(backend + path, item);
}

export function httpPut(path, item, user) {
    return axios.put(backend + path, item);
}

export function httpDelete(path, param, user) {
    return axios.delete(backend + path + '/' +param);
}