import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { httpDelete, httpGet, httpPost } from '../../fetch';
import { getRedirect } from '../helpers/redirect';
import moment from 'moment';

const Document = (props) => {
    const [isEdit, setEdit] = useState(false);
    const [last, setLast] = useState({});
    const [document, setDocument] = useState({date:'', signatory:[]});
    const [dateDisabled, setDateDisabled] = useState(false);
    const [persona, setPersona] = useState('');
    const [firmante, setFirmante] = useState({
        name:'',
        lastName:'',
        middleName: '',
        isElectronic: false,
        isAutographic: false
    });

    const [valid, setValid] = useState(false);

    useEffect(async () => {
        const href = window.location.href.split('/');
        const folder = href[href.length - 1]
        const id = folder.split('#')[0];
        if (id !== 'newDocument' && id !== 'editDocument'){
            setEdit(true)
            const { data } = await httpGet('/documents/' + id);
            setDocument({...data.data, date: moment(data.data.date).utc().format('YYYY-MM-DD')})
        } else{
            const s = folder.split('#')[1];
            setDocument({...document, series: decodeURI(s)})
            fetchLast();
        }
    }, [document.series])
    
    const fetchLast = async () =>{
        if(!isEdit){
            const { data } = await httpGet(`/documents/last/${document.series}`);
        if(data.data){
            setLast({...data.data, date: moment(data.data.date).utc()});
            setDocument({...document, date: moment().format('YYYY-MM-DD')});
            }
        }
    }

    const getNextSerial = () =>{
        if (!document.series){
            alert('Primero seleccione la serie');
            return
        }
        
        if (document.date && !moment(document.date).isAfter(moment(last.date).startOf('day')) && !isEdit) {
            alert(`La fecha debe ser posterior al ${moment(last.date).format('DD-MM-YYYY')}`)
            setValid(false);
        } else {
            setValid(true);
            let next = last.serial ? last.serial : 0;
            console.log(last);
            next ++;
            setDocument({...document, serial: next});
            setDateDisabled(true);
        }
    }

    const addFirmante = (e) =>{
        if( persona === '' || 
            (persona === 'fisica' && (firmante.name === '' || firmante.middleName === '' || firmante.lastName === '' || (firmante.isAutographic === false && firmante.isElectronic === false))) || 
            (persona === 'moral' && (firmante.name === '' || (firmante.isAutographic === false && firmante.isElectronic === false)))
            ){
            alert('Debe llenar todos los campos antes de continuar')
        } else{
            setDocument({ ...document, signatory: [...document.signatory, firmante] });
            setFirmante({
                name: '',
                lastName: '',
                middleName: '',
                isElectronic: false,
                isAutographic: false,
                nota:''
            })
        }
    }

    const save = async() =>{
        if (!document.serial) {
            alert('Debe generar el folio, antes de continuar');
            return;
        }
        if (!valid && !isEdit) {
            alert('La fecha seleccionada no es válida');
            return;
        }
        if (document.signatory.length < 2) {
            alert('Se requieren al menos dos firmantes');
            return;
        } 
        if(isEdit){
            await httpPost(`/documents/${document._id}`, document, 'site');
            window.location.href = getRedirect(document);
        }
        else if (document.signatory.length > 1 && document.serial && valid ) {
            await httpPost('/documents/', document, 'site');
            window.location.href = getRedirect(document);
        }
    }
    
    const cancel = () => {
        // setLast({});
        window.location.href = getRedirect(document);
    }

    const deleteEntry = async () => {
        await httpDelete(`/documents`,document._id,'site');
        window.location.href = getRedirect(document);
    }

    return (
        <Container>
            <br/>
            <Row style={{ borderBottom:'solid 1px #dee2e6', marginBottom: '1rem', paddingBottom: '1rem'}}>
                <Col xs={5}><h4>{isEdit?'Editar':'Nuevo'}</h4></Col>
                <Col xs={3}><Button onClick={e => cancel()}>Cancelar</Button></Col>
                <Col xs={3}><Button color='primary' onClick={e => save()} disabled={document.signatory && document.signatory.length < 2}>Guardar</Button></Col>
            </Row>
            <Form>
                <Row>
                    <Col sm={6} md={4}>
                        <FormGroup>
                            <Label htmlFor='series'>Serie</Label>
                            <Input type='select' value={document.series} onChange={e =>setDocument({...document, series: e.target.value})} disabled>
                                <option value=''>Seleccionar serie...</option>
                                <option value='Mediador 168'>Mediador 168</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col sm={6} md={4}>
                        <FormGroup>
                            <Label htmlFor='date'>Fecha</Label>
                            <Input name='date' id='date' type='date' value={document.date} onChange={e=> setDocument({...document, date: e.target.value})} disabled={dateDisabled}></Input>
                        </FormGroup>
                    </Col>
                    <Col sm={6} md={4}>
                        <FormGroup>
                            <Button disabled={isEdit} onClick={e => getNextSerial()} style={{marginTop: '2rem'}} 
                                disabled={!document.signatory || document.signatory.length < 2}>Generar Folio</Button>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6} md={4}>
                        <FormGroup>
                            <Label htmlFor='serial' >Folio</Label>
                            <Input type='text' id='serial' name='serial' value={document.serial} disabled />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                <Col sm={6} md={4}>
                        <FormGroup check>
                            <Label check>
                                <Input type="radio" name="radio3" onChange={() => setPersona('fisica')} value={persona} checked={persona === 'fisica'} />{' '}
                                Persona Física
                                </Label>
                        </FormGroup>
                    </Col>
                    <Col sm={6} md={4}>
                        <FormGroup check>
                            <Label check>
                                <Input type="radio" name="radio3" onChange={() => setPersona('moral')} value={persona} checked={persona === 'moral'} />{' '}
                                Persona Moral
                            </Label>
                        </FormGroup>
                    </Col>
                    <br />
                    <br />
                </Row>
                <Row>
                    {
                        persona === 'moral' ?
                        <Col sm={12} md={12}>
                            <FormGroup>
                                <Label htmlFor='name' >Razón social</Label>
                                <Input type='text' id='name' name='name' value={firmante.name} onChange={e => setFirmante({ ...firmante, name: e.target.value.toUpperCase() })}/>
                            </FormGroup>
                        </Col>
                    :
                    persona === 'fisica' ?
                        <React.Fragment>
                            <Col sm={6} md={4}>
                                <FormGroup>
                                    <Label htmlFor='name' >Nombres</Label>
                                    <Input type='text' id='name' name='name' value={firmante.name} onChange={e => setFirmante({ ...firmante, name: e.target.value.toUpperCase() })} />
                                </FormGroup>
                            </Col>
                            <Col sm={6} md={4}>
                                <FormGroup>
                                    <Label htmlFor='middleName' >Apellido Paterno</Label>
                                    <Input type='text' id='middleName' name='middleName' value={firmante.middleName} onChange={e => setFirmante({ ...firmante, middleName: e.target.value.toUpperCase() })} />
                                </FormGroup>
                            </Col>
                            <Col sm={6} md={4}>
                                <FormGroup>
                                    <Label htmlFor='lastName' >Apellido Materno</Label>
                                    <Input type='text' id='lastName' name='lastName' value={firmante.lastName} onChange={e => setFirmante({ ...firmante, lastName: e.target.value.toUpperCase() })} />
                                </FormGroup>
                            </Col>
                        </React.Fragment>
                    :''}
                    <Col sm={6} md={4}>
                        <FormGroup check>
                            <Label check>
                                <Input type="radio" name="radio2" onChange={e => setFirmante({...firmante, isAutographic: e.target.checked})} value={firmante.isAutographic} checked={firmante.isAutographic} />{' '}
                                Firma Autógrafa
                                </Label>
                        </FormGroup>
                    </Col>
                    <Col sm={6} md={4}>
                        <FormGroup check>
                            <Label check>
                                <Input type="radio" name="radio2" onChange={e => setFirmante({ ...firmante, isElectronic: e.target.checked })} value={firmante.isElectronic} checked={firmante.isElectronic} />{' '}
                                Firma Electrónica
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm={6} md={4}>
                        <Button onClick={e => addFirmante(e)}>Agregar</Button>
                    </Col>
                </Row>
            </Form>
            <br />
            {
                document.signatory && document.signatory.length < 2 ? 
                (
                    <p style={{color: 'red'}}>Se requieren al menos dos firmantes</p>
                )
                : ''
            }
            <h6>Firmantes</h6>
            <Table>
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Tipo firma</th>
                        <th style={{textAlign:'center'}}></th>
                        <th style={{textAlign:'center'}}></th>
                    </tr>
                </thead>
                <tbody>
                    {document.signatory && document.signatory.map(s => {
                        return (
                            <tr key={document.signatory.indexOf(s)}>
                                <td>{s.name} {s.middleName} {s.lastName}</td>
                                <td>{s.isElectronic ? 'Electrónica' : 'Autógrafa'}</td>
                                {/* <td style={{textAlign: 'center'}}>
                                    <Input type='checkbox' value={s.isSigned} checked={s.isSigned} onChange={e =>
                                        setDocument({ ...document, signatory: document.signatory.map(x => x === s ? ({ ...x, isSigned: !s.isSigned }) : x) })}></Input>
                                </td> */}
                                <td style={{ textAlign: 'center' }}><a href='#' onClick={e => {
                                    setDocument({ ...document, signatory: document.signatory.filter(x => x !== s) })
                                }}>Eliminar</a></td>
                                <td style={{ textAlign: 'center' }}><a href='#' onClick={e => {
                                    const selected = document.signatory.filter(x => x === s)[0]
                                    setDocument({ ...document, signatory: document.signatory.filter(x => x !== s) })
                                    selected.lastName && selected.lastName !== '' ? setPersona('fisica') : setPersona('moral')
                                    setFirmante(selected)
                                }}>Editar</a></td>
                            </tr>)
                    })}
                </tbody>
            </Table>
            <br />
            <Row>
                {isEdit ? 
                <Col sm={12}>
                    <h6>Notas</h6>
                    <Table>
                        <thead>
                            <th>Fecha</th>
                            <th>Usuario</th>
                            <th>Nota</th>
                            <th></th>
                        </thead>
                        <tbody>
                            {document.notes ? document.notes.map(n =>{
                                return(
                                    <tr>
                                        <td>{moment(n.date).utc().format('DD/MM/YYYY')}</td>
                                        <td>{n.user}</td>
                                        <td>{n.note}</td>
                                        <td style={{ textAlign: 'center' }}><a href='#' onClick={e => {
                                            setDocument({ ...document, notes: document.notes.filter(x => x !== n) })
                                        }}>Eliminar</a></td>
                                    </tr>
                                )
                            }):''}
                        </tbody>
                    </Table>
                </Col>
                : ''}
            </Row>

            {isEdit ?
                <Button color='warning' 
                    onClick={() => { if (window.confirm('¿Estás seguro que deseas eliminar este folio?')) deleteEntry() }}>Eliminar este folio</Button>
            :''}
        </Container>
    );
};

export default Document;