import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import axios from 'axios'
import PrivateRoute from './PrivateRoute';

//NAVIGATION ITEMS
import Login from './psa_modules/Login';
import Main from './psa_modules/Main';
import Document from './psa_modules/pages/Document';
import Detail from './psa_modules/pages/Detail';
import User from './psa_modules/pages/User';

function App() {

  function toMain() {
    window.location.href = '/main'
  }

  function isSignedUp() {
    return true;
  }


  return (
    <Router>
      <Route path="/" exact component={Login} />
      <Route path="/login" exact component={Login} />
      <PrivateRoute exact path='/main' component={Main}></PrivateRoute>
      <PrivateRoute exact path='/newDocument' component={Document}></PrivateRoute>
      <PrivateRoute exact path='/editDocument/*' component={Document}></PrivateRoute>
      <PrivateRoute exact path='/detail/*' component={Detail}></PrivateRoute>
      <PrivateRoute exact path='/newUser' component={User}></PrivateRoute>
      <PrivateRoute exact path='/editUser/*' component={User}></PrivateRoute>
    </Router>
  );
}

export default App;
