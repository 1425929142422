import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Button, Input, Card, CardBody, CardTitle, CardSubtitle, CardText, FormGroup, Label } from 'reactstrap';
import { httpGet } from '../../fetch';
import SearchField from 'react-search-field';
import xlsx from 'node-xlsx';

function Documents(props) {

    const [data, setData] = useState([]);
    const [from, setFrom] =useState(moment().format('YYYY-MM-DD'));
    const [to, setTo] =useState(moment().format('YYYY-MM-DD'));

    function getUserType() {
        return localStorage.userType === 'Admin'
    }

    useEffect(() => {
        if(props.series !== "")
            fetchDocuments(props.series);
    }, [props.series])

    const onSearch = (value, event) =>{
        if(value !== ''){
            const newData = data.filter(d => {
                if(d.serial.toString().toUpperCase().indexOf(value) > -1)
                    return d;

                const inSigner = d.signatory.filter(s =>{
                    if(s.name.toUpperCase().indexOf(value.toUpperCase()) > -1 || 
                        s.middleName.toUpperCase().indexOf(value.toUpperCase()) > -1 || 
                        s.lastName.toUpperCase().indexOf(value.toUpperCase()) > -1)  
                        return s
                });

                if(inSigner.length > 0)
                    return d;
                
                const inNotes = d.notes && d.notes.length > 0  && getFixedOrLast(d.notes, false)? getFixedOrLast(d.notes, false).toUpperCase().indexOf(value.toUpperCase()) > -1 : false

                if(inNotes)
                    return d;
            })
            setData(newData);
        } else{
            fetchDocuments();
        }
    }

    const filterByDate = () =>{
        const newData = data.filter(d => {
            if(moment(d.date).isBetween(moment(from), moment(to))) return d;
        });
        setData(newData);
    }

    const fetchDocuments = async (serie = props.series) =>{
        const { data } = await httpGet(`/documents?series=${serie}`);
        setData(data.data);
    }

    const clearSearch = () =>{
        console.log('clear');
        let x = document.querySelector('input.react-search-field-input');
        x.value='';
        fetchDocuments();
    }

    const getSignatoryCount = (d) =>{
        let count = 0;
        let signed = 0;
        d.signatory.map(s=>{
            if(s.isSigned) signed ++;
            count ++
        })
        return [signed, count]
    }

    const fetchComplete = () =>{
        const newData = data.filter(d => {
            const count = getSignatoryCount(d);
            if(count[0] === count[1]) return d
        });

        setData(newData);
    }

    const fetchIncomplete = () =>{
        const newData = data.filter(d => {
            const count = getSignatoryCount(d);
            if (count[0] < count[1]) return d
        });

        setData(newData);
    }

    const fetchCanceled = () =>{
        const newData = data.filter(d => {
            const canceled = d.notes.filter(nt => nt.note === 'FIRMA CANCELADA');
            if(canceled.length > 0) return d
        });

        setData(newData);
    }

    const getPrefix = (series) => {
        if (series === 'Mediador 168') return '168-';
        else return '';
    } 

    const saveReportFile = (reportName, byte) => {
        var blob = new Blob([byte], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var fileName = reportName;
        link.download = fileName;
        link.click();
    };

    const getReport = () =>{
        let sheetData = [];
        const colsSheetData = ['Serie', 'Folio', 'Fecha',  'Firmas', 'Status', 'Nota'];
        let maxSignatory = 0;

        const getSignersCol = (signatory) => {
            const row = [];
            signatory.map(s => {
                const cols = []
                cols.push(`${s.name} ${s.middleName ? s.middleName : '' } ${s.lastName ? s.lastName : ''}`);
                cols.push(s.isSigned ? 'SI' : 'NO');
                cols.push(s.user ? s.user : '');
                row.push(cols)
            })

            if (signatory.length > maxSignatory) maxSignatory = signatory.length

            return row.flat()
        }

        data.map(d =>{
            const firmas = getSignatoryCount(d);
            sheetData.push([
                d.series, 
                d.serial, 
                moment(d.date).utc().format('DD/MM/YYYY'),
                `${firmas[0]+'/'+firmas[1]}`, 
                `${firmas[0] === firmas[1] ? 'Completo':'Incompleto'}`,
                getFixedOrLast(d.notes, false)
            ].concat(getSignersCol(d.signatory)))
        });

        const options = {'!cols': [{ wch: 13 }, { wch: 7 }, { wch: 12 }, { wch: 7 }, { wch: 10 }, { wch: 40 }, { wch: 10 }, { wch: 15 }, { wch: 40 }, { wch: 10 }, { wch: 15 }, { wch: 40 }, { wch: 10 }, { wch: 15 }, { wch: 40 }, { wch: 10 }, { wch: 15 }, { wch: 40 }, { wch: 10 }, { wch: 15 }, { wch: 40 }, { wch: 10 }, { wch: 15 }]};

        for (let i = 1; i <= maxSignatory; i++) {
            colsSheetData.push('Firmante '+i);
            colsSheetData.push('Firmado');
            colsSheetData.push('Usuario');
        }

        const dataset = []
        dataset.push(colsSheetData)
        sheetData.map(m => {
            dataset.push(m)
        });

        const buildData = xlsx.build([{name: "Folios", data: dataset}], options);
        saveReportFile(`Reporte - ${moment().utc().format('YYYYMMDD')}`, buildData);
    }

    const getFixedOrLast = (notes, render = true) =>{
        const fixed = notes.filter(nt => nt.isFixed);
        if(fixed.length > 0){
            if (render)
                return (
                    <p className='mt-3 font-italic'>{fixed[0].note}</p>
                )
            else return fixed[0].note
        } else if (notes && notes.length > 0){
            if (render)
                return (
                    <p className='mt-3 font-italic'>{notes[notes.length -1].note }</p>
                )
            else return notes[notes.length -1].note
        } 
    }

    return (
        <Container>
            <br/>
            <Row>
                <Col sm={10}><h4>Folios - {props.series}</h4></Col>
                <Col sm={2} style={{textAlign: 'right'}}><Button onClick={e => {window.location.href = `/newDocument#${props.series}`}}>+ Nuevo</Button></Col>
            </Row>
            <br />
            <Row>
                <Col xs={12} md={4}>
                    <FormGroup>
                        <SearchField
                            id='searcher'
                            placeholder='Buscar'
                            onChange={onSearch}
                            classNames="search-class"
                        />
                    </FormGroup>
                </Col>
                <Col xs={4} md={2}>
                    <Button onClick={e => clearSearch()}>Limpiar</Button>
                </Col>
                <Col xs={4} md={2}>
                    <Button onClick={e => fetchComplete()}>Completos</Button>
                </Col>
                <Col xs={4} md={2}>
                    <Button onClick={e => fetchIncomplete()}>Incompletos</Button>
                </Col>
                <Col xs={4} md={2}>
                    <Button onClick={e => fetchCanceled()}>Cancelados</Button>
                </Col>
            </Row>
            <Row>
                <Col sm={6} md={4}>
                    <FormGroup>
                        <Label htmlFor='from' >Desde</Label>
                        <Input type='date' id='from' name='from' value={from} onChange={e => setFrom(e.target.value)} />
                    </FormGroup>
                </Col>
                <Col sm={6} md={4}>
                    <FormGroup>
                        <Label htmlFor='to' >Hasta</Label>
                        <Input type='date' id='to' name='to' value={to} onChange={e => setTo(e.target.value)} />
                    </FormGroup>
                </Col>
                <Col sm={6} md={2}>
                    <FormGroup>
                        <Button onClick={()=> filterByDate()} style={{marginTop: '2rem'}}>Filtrar</Button>
                    </FormGroup>
                </Col>
                <Col sm={6} md={2}>
                    <FormGroup>
                        <Button onClick={()=> getReport()} style={{marginTop: '2rem'}}>Reporte</Button>
                    </FormGroup>
                </Col>
            </Row>
            <br />
            <Row>
                {data.map(d=>{
                    return(
                            <Col sm={12} md={6} lg={4} key={d._id}>
                                <Card >
                                    <CardBody>
                                        <CardTitle tag='h5'>Folio: {`${getPrefix(d.series)}${d.serial}`}</CardTitle>
                                        <CardSubtitle tag='h6'>Creación: {moment(d.date).utc().format('DD/MM/YYYY')} <br /> {d.updatedAt ? `Revisión: ${moment(d.updatedAt).utcOffset("+5").format('DD/MM/YYYY')}`:''}</CardSubtitle>
                                        <br />
                                        <CardSubtitle tag='h6'>Firmas: {getSignatoryCount(d)[0]}/{getSignatoryCount(d)[1]}</CardSubtitle>
                                        <CardText>
                                            {d.signatory.map(s =>{
                                                return(
                                                    <span key={d.signatory.indexOf(s)} style={{color: !s.isSigned ? 'red' : 'inherit'}}>{s.name} {s.middleName} {s.lastName} <br /></span>
                                                )
                                            })}
                                            {d.notes && d.notes.length > 0 && getFixedOrLast(d.notes)}
                                        </CardText>
                                        <Button color='primary' onClick={e => { window.location.href = '/detail/'+d._id }}>Firmar</Button>
                                        {getUserType()?
                                            <Button style={{ marginLeft: '1rem' }} onClick={e => { window.location.href = '/editDocument/' + d._id }}>Editar</Button>
                                        :''}
                                    </CardBody>
                                </Card>
                            </Col>
                        );
                    })
                }
            </Row>
        </Container>
    );
}

export default Documents;