import React from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import jwt_decode from "jwt-decode";

export function isSignedUp() {
    const token = localStorage.getItem('token');
    if(! token) return false;
    const { exp } = jwt_decode(token)
    const expirationTime = (exp * 1000) - 60000
    if (Date.now() <= expirationTime) {
        console.log(`Tu sesión expira el ${new Date(expirationTime)}`);
        if (token) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        }
        return token;
    }
}

function PrivateRoute(props) {
    const { component: Component, ...rest } = props
    return (
        <Route {...rest} render={props => isSignedUp() ?
            <Component {...props} /> :
            <Redirect to={{
                pathname: "/"
            }} />
        }
        />
    )
}

export default PrivateRoute;